define(['app', '$window', 'elementViewed'], function(app, $window, elementViewed) {
  var brandsAToZ = function() {
    var comp = {};
    comp.elementViewed = elementViewed;


    var _config = {
      selectors: {
        brandGroupWrapper: '.brandsAToZ_brandGroup_wrapper'
      },
      classes: {
        brandGroupWrapperClass: 'brandsAToZ_brandGroup_wrapper',
        brandGroupWrapperHoverClass: 'brandsAToZ_brandGroup_wrapper-hover'
      }
    };

    var _init = function(el, config = {}) {
      comp.config = Object.assign({}, comp.config, config);

      comp.element = el;
      comp.brandGroupWrappers = el.querySelectorAll(comp.config.selectors.brandGroupWrapper);

      comp.brandGroupWrappers.forEach(function (wrapper) {
        wrapper.addEventListener('mouseover', comp.hoverGroup);
        wrapper.addEventListener('focusin', comp.hoverGroup);
      });

      comp.brandGroupWrappers[0].classList.add(comp.config.classes.brandGroupWrapperHoverClass);
      comp.config.hoveredGroup = comp.brandGroupWrappers[0];

      comp.bind();

      return comp;
    };

    var _bind = function(e) {
      comp.elementViewed(comp.element, comp.brandsAToZViewed);
    };

    var _hoverGroup = function(e) {
      const wrapper = e.target.classList.contains(comp.config.classes.brandGroupWrapperClass) ? e.target : e.target.parentElement;
      if (wrapper.classList.contains(comp.config.classes.brandGroupWrapperClass)) {
        if (comp.config.hoveredGroup) {
          comp.config.hoveredGroup.classList.remove(comp.config.classes.brandGroupWrapperHoverClass);
        }
  
        wrapper.classList.add(comp.config.classes.brandGroupWrapperHoverClass);
        comp.config.hoveredGroup = wrapper;
      }
    };

    var _brandsAToZClicked = function() {
      app.publish('tracking/record', 'Brands A to Z Track', 'Clicked', comp.element.getAttribute("data-brand-name"));
    };

    var _brandsAToZViewed = function() {
      app.publish('tracking/record', 'Brands A to Z Track', 'Viewed', comp.element.getAttribute("data-brand-name"));
    };



    comp.init = _init;
    comp.bind = _bind;
    comp.brandsAToZViewed = _brandsAToZViewed;
    comp.brandsAToZClicked = _brandsAToZClicked;
    comp.config = _config;
    comp.hoverGroup = _hoverGroup;

    return comp;
  };

  return brandsAToZ;
});
